<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    
  >
  <!-- src="../../assets/banner.jpg" -->
    <v-list dense nav>
      <!---USer Area -->
      <v-list-item two-line class="px-0" color="white" >
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/men/81.jpg" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{$store.state.currentUser.user ? $store.state.currentUser.user.name : ''}}</v-list-item-title>
          <v-list-item-subtitle class="caption">{{$store.state.currentUser.user ? $store.state.currentUser.user.role.role_name.name : ''}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!---USer Area -->
      <hr color="white"/>
      <!---Sidebar Items -->
      
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.link"
        :name="item.link"
        :active-class="`itemOver white--text`"
        link
        class="itemss"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        
        
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          style="text-decoration: none !important;"
					v-for="(admin, i) in admins"
					:key="i"
          :to="admin[1]"
          :name="admin[1]"
          :active-class="`itemOver white--text`"
			>
          
          <v-list-item-icon>
            <v-icon>{{ admin[2] }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
					<v-list-item-title v-text="`\t  `+admin[0]"></v-list-item-title>
          </v-list-item-content>
					
			</v-list-item>

      <v-list-item @click="logOut()">
        <v-list-item-icon>
          <v-icon>lock</v-icon>
        </v-list-item-icon>
        
        
        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
      
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // items: [
    //   {
    //     title: "Dashboard",
    //     icon: "mdi-view-dashboard",
    //     to: "/dashboard/basic-dashboard"
    //   },

    //   {
    //     title: "Profile",
    //     icon: "mdi-account-circle",
    //     to: "/pages/profile"
    //   },

    //   {
    //     title: "Alerts",
    //     icon: "mdi-alert",
    //     to: "/pages/alerts"
    //   },

    //   {
    //     title: "Icons",
    //     icon: "mdi-emoticon",
    //     to: "/pages/icons"
    //   },

    //   {
    //     title: "Basic Table",
    //     icon: "mdi-table-column-width",
    //     to: "/pages/tables-simple"
    //   }
    // ]
    admins: [
					['Role', 'roles', 'military_tech'],
					['Admin', 'admin', 'mdi-account-group-outline']
		],
    items: [
					{
						icon: 'dashboard',
						link: 'dashboard',
						text: 'Dashboard'
					},
					{
						icon: 'commute',
						link: 'cars',
						text: 'Cars'
					},
					{
						icon: 'recent_actors',
						link: 'users',
						text: 'Users'
					},
					{
						icon: 'departure_board',
						link: 'trips',
						text: 'Trips'
					},
					{
						icon: 'monetization_on',
						link: 'earnings',
						text: 'Earnings'
					}

				]
  }),
  computed: {
    ...mapState('menu/',["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.menu.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("menu/SET_SIDEBAR_DRAWER", val);
      }
    }
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    }
  },

  methods: {
    logOut () {
        console.log('logout')
        this.axios.post('/logout', {}, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("id_token")
            }
				})
				.then(response => {
          localStorage.removeItem('id_token')
					// console.log(response)
					this.$router.replace('/login')
                    // this.cars = response.data.cars
                    // this.loading = false
				})
				.catch(error => {
					console.log(error)
					localStorage.removeItem('id_token')
					this.$router.replace('/login')
                    // this.loading = false
				})
    }
  }
};
</script>
<style lang="scss">
#main-sidebar{
  box-shadow:1px 0 20px rgba(0,0,0,.08);
  -webkit-box-shadow:1px 0 20px rgba(0,0,0,.08);
  .v-navigation-drawer__border{
    display: none;
  }
  .v-list{
    padding: 8px 15px;
  }
  .v-list-item{
      &__icon--text,
      &__icon:first-child{
        justify-content: center;
        text-align: center;
        width: 20px;
        
      }
      
  } 
  router-link{
      text-decoration: none;
  }
  router-link a{
      text-decoration: none !important;
  }
}

.itemOver{
  background-color: #101820!important;
  text-decoration: none !important;
  color: #ffb719 !important;
}
.itemOverColor{
  color: rgb(16, 24, 32) !important;
}
.itemss{
  text-decoration: none !important;
}
</style>